var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Timer from "@/components/Timer";
import useCountdown from "@/hooks/useCountdown";
import { calculatePlayerScores } from "shared/utils/logic";
import { MatchFormat, MatchState } from "shared/models/matchModels";
import { ReactComponent as Logo } from "../../logo.svg";
import TVScoreTable from "@/components/TVScoreTable";
import TVCourtDisplay from "@/components/TVCourtDisplay";
var Info = function (_a) {
    var data = _a.data;
    var theme = useTheme();
    var playersScore = calculatePlayerScores(data.rounds);
    var _b = useCountdown(data.limit, data.format === MatchFormat.TIMED &&
        data.state === MatchState.IN_PROGRESS), initialCountdown = _b.initialCountdown, showTimer = _b.showTimer, countdown = _b.countdown, timerFinished = _b.timerFinished, formatTime = _b.formatTime;
    return (_jsx(Box, __assign({ sx: {
            height: "100vh",
            padding: 3,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 3, sx: { height: "100%" } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sx: { height: "15%" } }, { children: _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%" }, { children: [_jsx(Logo, { width: "15%", height: "auto" }), _jsx(Typography, __assign({ variant: "h2", sx: { fontWeight: "bold" } }, { children: data.sessionName })), _jsxs(Typography, __assign({ variant: "h3", sx: { fontWeight: "bold", color: "primary.main" } }, { children: ["Round ", data.round + 1, "/", data.rounds.length] }))] })) })), showTimer ? (_jsx(Grid, __assign({ item: true, xs: 12, sx: { height: "85%" } }, { children: _jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }, { children: _jsx(Timer, { initialCountdown: initialCountdown, countdown: countdown, formatTime: formatTime }) })) }))) : timerFinished ? (_jsx(Grid, __assign({ item: true, xs: 12, sx: { height: "85%" } }, { children: _jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }, { children: _jsx(Typography, __assign({ variant: "h1", align: "center", sx: { fontWeight: "bold", color: "secondary.main" } }, { children: "Please submit scores" })) })) }))) : (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 8, sx: { height: "85%" } }, { children: _jsx(TVScoreTable, { playersScore: playersScore, playerDatas: data.playerList }) })), _jsx(Grid, __assign({ item: true, xs: 4, sx: { height: "85%" } }, { children: _jsx(TVCourtDisplay, { games: data.rounds[data.round].games, playerList: data.playerList }) }))] }))] })) })));
};
export default Info;
