var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper, useTheme } from "@mui/material";
import { MatchFormat } from "shared/models/matchModels";
import QRCode from "react-qr-code";
import { ReactComponent as Logo } from "../../logo.svg";
var MatchLobby = function (_a) {
    var data = _a.data, matchId = _a.matchId;
    var theme = useTheme();
    var link = "http://" + window.location.host + "/match/" + matchId;
    var PlayerList = function () { return (_jsx(Grid, __assign({ container: true, spacing: 2 }, { children: data.playerList.length > 0 ? (data.playerList.map(function (player, index) { return (_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Typography, __assign({ variant: "h5", sx: { mb: 1 } }, { children: player.name })) }), index)); })) : (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h5" }, { children: "Waiting for players to join..." })) }))) }))); };
    return (_jsx(Box, __assign({ sx: {
            height: "100%",
            padding: 4,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 4, sx: { height: "100%" } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", height: "100%" }, { children: [_jsx(Box, __assign({ mb: 4 }, { children: _jsx(Logo, { width: "100%", height: "auto", style: { maxHeight: "20vh" } }) })), _jsx(Typography, __assign({ variant: "h2", sx: { mb: 7 } }, { children: data.sessionName })), _jsxs(Paper, __assign({ elevation: 3, sx: {
                                    p: 3,
                                    backgroundColor: theme.palette.background.paper,
                                    mb: 4,
                                } }, { children: [_jsxs(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: ["Format: ", data.format === MatchFormat.TIMED ? "Timed" : "Points"] })), _jsxs(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: [data.format === MatchFormat.TIMED
                                                ? "Time Per Round:"
                                                : "Points to Win:", " ", data.limit, " ", data.format === MatchFormat.TIMED ? "minutes" : "points"] })), _jsxs(Typography, __assign({ variant: "h4" }, { children: ["Player Amount: ", data.players] }))] }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "auto" }, { children: [_jsxs(Paper, __assign({ elevation: 3, sx: {
                                    p: 3,
                                    backgroundColor: theme.palette.background.paper,
                                    flexGrow: 1,
                                    overflowY: "auto",
                                } }, { children: [_jsxs(Typography, __assign({ variant: "h3", gutterBottom: true }, { children: ["Players (", data.playerList.length, "/", data.players, ")"] })), _jsx(PlayerList, {})] })), _jsxs(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center", mt: 4 }, { children: [_jsx(QRCode, { value: link, size: 200 }), _jsx(Typography, __assign({ variant: "h5", sx: { mt: 2 } }, { children: "Scan to join the match" }))] }))] })) }))] })) })));
};
export default MatchLobby;
