var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { medalColors } from "@/theme/theme";
import { Box, Typography, Grid, Paper } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import React from "react";
var TVScoreTable = function (_a) {
    var playersScore = _a.playersScore, playerDatas = _a.playerDatas;
    var getMedalColor = function (index) {
        switch (index) {
            case 0:
                return medalColors.gold.main;
            case 1:
                return medalColors.silver.main;
            case 2:
                return medalColors.bronze.main;
            default:
                return "background.paper";
        }
    };
    return (_jsxs(Box, __assign({ sx: { height: "100%", display: "flex", flexDirection: "column" } }, { children: [_jsx(Typography, __assign({ variant: "h4", gutterBottom: true, sx: { fontWeight: "bold", color: "primary.main" } }, { children: "Leaderboard" })), _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: playersScore.map(function (player, index) { return (_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(Paper, __assign({ elevation: 2, sx: {
                            p: 2,
                            backgroundColor: getMedalColor(index),
                            color: index < 0 ? "common.white" : "text.primary",
                        } }, { children: _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [index < 3 && _jsx(EmojiEventsIcon, { sx: { mr: 1 } }), _jsxs(Typography, __assign({ variant: "h6", sx: { fontWeight: "bold" } }, { children: [index + 1, ". ", playerDatas[player.playerIndex].name] }))] })), _jsx(Typography, __assign({ variant: "h6", sx: { fontWeight: "bold" } }, { children: player.playerScore }))] })) })) }), index)); }) }))] })));
};
export default TVScoreTable;
