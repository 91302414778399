var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Typography, Container, Paper, Grid, useTheme, Button, } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import { ExitToApp } from "@mui/icons-material";
var SpectatorLobby = function (_a) {
    var matchId = _a.matchId, data = _a.data;
    var theme = useTheme();
    return (_jsx(Container, __assign({ maxWidth: "md", sx: { mt: 4, mb: 4 } }, { children: _jsxs(Paper, __assign({ elevation: 3, sx: { p: 3, borderRadius: 2 } }, { children: [_jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }, { children: [_jsx(Typography, __assign({ variant: "h4", component: "h1", sx: { fontWeight: "bold" } }, { children: data.sessionName })), _jsxs(Typography, __assign({ variant: "h6", color: "primary" }, { children: ["Match ID: ", matchId] }))] })), _jsxs(Paper, __assign({ elevation: 2, sx: {
                        p: 2,
                        mb: 3,
                        backgroundColor: theme.palette.background.default,
                    } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", mb: 2 }, { children: [_jsx(PeopleIcon, { sx: { mr: 1, color: "primary.main" } }), _jsxs(Typography, __assign({ variant: "h5", component: "h2" }, { children: ["Players (", data.playerList.length, "/", data.players, ")"] }))] })), _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: data.playerList.map(function (player, index) { return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(Paper, __assign({ elevation: 1, sx: { p: 2, display: "flex", alignItems: "center" } }, { children: [_jsx(PersonIcon, { sx: { mr: 1, color: "secondary.main" } }), _jsx(Typography, __assign({ variant: "body1" }, { children: player.name }))] })) }), index)); }) }))] })), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "body2", color: "textSecondary" }, { children: "Waiting for the match to start..." })), _jsx(Button, __assign({ variant: "contained", color: "secondary", startIcon: _jsx(ExitToApp, {}), onClick: function () {
                                /* Leave functionality to be implemented */
                            } }, { children: "Leave Match" }))] })] })) })));
};
export default SpectatorLobby;
