var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, Grid, Paper } from "@mui/material";
import React from "react";
var TVCourtDisplay = function (_a) {
    var games = _a.games, playerList = _a.playerList;
    return (_jsxs(Box, __assign({ sx: { height: "100%", display: "flex", flexDirection: "column" } }, { children: [_jsx(Typography, __assign({ variant: "h4", gutterBottom: true, sx: { fontWeight: "bold", color: "secondary.main" } }, { children: "Current Games" })), _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: games.map(function (game, index) { return (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Paper, __assign({ elevation: 2, sx: { p: 2, backgroundColor: "secondary.light" } }, { children: [_jsxs(Typography, __assign({ variant: "h6", align: "center", sx: { fontWeight: "bold", mb: 1 } }, { children: ["Game ", index + 1] })), _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Typography, __assign({ variant: "body1" }, { children: [playerList[game.team1[0]].name, "/", playerList[game.team1[1]].name] })), _jsx(Typography, __assign({ variant: "h6", sx: { fontWeight: "bold" } }, { children: "VS" })), _jsxs(Typography, __assign({ variant: "body1" }, { children: [playerList[game.team2[0]].name, "/", playerList[game.team2[1]].name] }))] }))] })) }), index)); }) }))] })));
};
export default TVCourtDisplay;
